<template src='./App.html'></template>
<style scoped>
@import "./App.css";
</style>
<script>
import Main from "../Main/Main.vue";
import Header from "../Header/Header.vue";
import Footer from "../Footer/Footer.vue";

export default {
  name: "App",

  components: {
    Main,
    Header,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>
