<template src='./Header.html'></template>
<style scoped>
@import "./Header.css";
</style>
<script>
export default {
  name: "HeaderPage",
  data: () => ({
    //
  }),
};
</script>