<template src='./Main.html'></template>
<style scoped>
@import "./Main.css";
</style>
<script>
export default {
  name: "MainPage",

  data: () => ({
    sites: [
      {
        img: "site_dobrynya",
        desc: "Реализация MODx, JS,jQuery",
        url: "http://igor.vsza.ru",
        title: "Добрыня - сайт недвижимости в Санкт-Петербурге",
      },
      {
        img: "site_geovision",
        desc: "Сайт-визитка,реализация Wordpress, JS,jQuery",
        url: "http://geovision.vsza.ru",
        title: "Компания Geovision",
      },
      {
        img: "site_gigaterm",
        desc: "Промышленное оборудование для систем отопления и водоснабжения,реализация MODx, JS,jQuery",
        url: "http://gigaterm.vsza.ru",
        title: "Гигатерм - промышленное обрудование",
      },
      {
        img: "site_idoors",
        desc: "Межкомнатные, входные,раздвижные двери,реализация MODx, JS,jQuery",
        url: "http://idoors.vsza.ru",
        title: "Двери по низким ценам",
      },
      {
        img: "site_kedrov",
        desc: "Группа отелей Кедров,реализация Wordpress, JS,jQuery",
        url: "http://kedrov.vsza.ru",
        title: "Кедров-отели",
      },
      {
        img: "site_magha",
        desc: "Сайт-астропроцессор. Основная задача построение натальных карт,реализация MODx, Vue.js,Vuetify,D3.js",
        url: "http://maitreya.vsza.ru",
        title: "Magha - астрологический процессор",
      },
      {
        img: "site_oceanolog",
        desc: "Научно-популярный портал о природе. Реализация Drupal,PHP,JS",
        url: "http://www.oceanolog.ru",
        title: "Научно-популярный портал",
      },
      {
        img: "site_tureykapark",
        desc: "Недвижимость в коттеджном поселке. Реализация MODx,JS",
        url: "http://www.tureyka-park.ru",
        title: "Коттеджный поселок",
      },
    ],
    landings: [
      {
        img: "land_lpburo",
        desc: "Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/lpburo/",
        title: "БАУ Проект",
      },
      {
        img: "land_lptech",
        desc: "Геотехнические расчеты в строительстве. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/lptech/",
        title: "БАУ Проект, Геотехнические расчеты",
      },
      {
        img: "land_fdoors",
        desc: "Противопожарные двери и ворота в Крыму. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/fdoors/",
        title: "Сталь-стройтехно",
      },
      {
        img: "land_offervrk",
        desc: "Производство и доставка сэндвич-панелей. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/offervrk/",
        title: "ВРК1 Сэндвич-панели",
      },
      {
        img: "land_lptechmastera",
        desc: "Геотехнические работы на объектах Москвы и Московской области. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/lptechmastera/",
        title: "БАУ Проект Геотехнические работы",
      },
      {
        img: "land_salegoldring",
        desc: "БЕСПЛАТНЫЙ МАСТЕР-КЛАСС 'ПРОДАЖИ БЕЗ ВОЗРАЖЕНИЙ'. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/salegoldring/",
        title: "Тренинг 'Мастер продаж'",
      },
      {
        img: "land_texas",
        desc: "Продаже портмоне. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/texas/",
        title: "Texas genuine",
      },
      {
        img: "land_urist",
        desc: "Единая база автоюристов. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/urist/",
        title: "Автоюристы",
      },
      {
        img: "land_zamug",
        desc: "Школа взаимоотношений мужчины и женщины Надежды Хомяниной. Реализация HTML, JS,jQuery",
        url: "http://vsza.ru/zamug/",
        title: "Школа взаимоотношений",
      },
    ],
    calcquiz: [
      {
        img: "calc_gidroscalc",
        desc: "Калькулятор для расчета фильтров воды по параметрам. Реализация Vue.js, Vuetify",
        url: "http://vsza.ru/gidroscalc/",
        title: "Калькулятор фильтров воды",
      },
      {
        img: "calc_exdesigner",
        desc: "Конструктор экзаменов для учебного центра с различными вариантами вопросов. Реализация HTML,JS, JQuery",
        url: "http://vsza.ru/exdesigner/",
        title: "Конструктор экзаменов",
      },
      {
        img: "quiz_frankilabs",
        desc: "Медицинский опросник с подсчетом баллов по ответам, выдачей результата и отправкой результатов по email. Реализация Vue.js,Vuetify",
        url: "http://vsza.ru/frankilabs/",
        title: "Медецинский опросник",
      },
    ],
  }),
};
</script>
