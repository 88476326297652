<template src='./Footer.html'></template>
<style scoped>
@import "./Footer.css";
</style>
<script>
export default {
  name: "FooterPage",
  data: () => ({
    //
  }),
};
</script>